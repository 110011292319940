import { StatusType } from "@/types/Subscription";
import { addHours, format } from "date-fns";
import { id } from "date-fns/locale";

export const capitalizeFirstLetter = (s: string) =>
  s[0].toUpperCase() + s.slice(1);

export function titleCase(str: string) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}

export function titleCaseKurungBuka(str: string) {
  var splitStr = str.toLowerCase().split('(');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join('(');
}

export const handleDigit = (value: string) => {
  //clean non digit karakter
  return value.replace(/\D/g, "");
};

export const handleNumber = (value: string) => {
  //clean 0 di depan misal 0088828
  return handleDigit(value).replace(/^0+(?!$)/g, "");
};

export const handleCurrency = (value: string) => {
  //parse ke currency
  const newValue = handleNumber(value);
  function numberWithCommas(num: string) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  return numberWithCommas(newValue);
};

const isoStringRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3,}/

export const formatBackendDate = (date: string) =>
  format(new Date(`${date}Z`), "d MMMM yyyy HH:mm:ss", {
    locale: id,
  });


export const forceBackendDateToWIB = (date: string) => `${format(
  addHours(new Date(date), 7),
  "d MMMM yyyy HH:mm:ss", {
  locale: id,
}
)} WIB`

export const formatBackendDateOnly = (date: string) =>
  format(isoStringRegex.test(date) ? new Date(`${date}Z`) : new Date(date), "d MMMM yyyy", {
    locale: id,
  });

export const formatBackendTimeOnly = (date: string) =>
  format(isoStringRegex.test(date) ? new Date(`${date}Z`) : new Date(date), "HH:mm:ss", {
    locale: id,
  });

export const convertPaymentMethodEnumToText = (data: StatusType) =>
  HumanText[data];

const HumanText = {
  PENDING1: "Menunggu pembayaran #1",
  PENDING2: "Menunggu pembayaran #2",
  EXP1: "Pembayaran Kadaluarsa #1",
  EXP2: "Pembayaran Kadaluarsa #2",
  ACCEPT: "Pembayaran Diterima",
  SUCCESS: "Pembayaran Berhasil",
  CANCEL: "Pembayaran Dibatalkan",
  FAIL: "Pembayaran Gagal",
  DENY: "Pembayaran Ditolak",
};

export const addThousandSeparator = (num: string | number) => {
  if (typeof num === "string") return num.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const convertToHourFormat = (data: number) =>
  `${data.toString().length === 1 ? `0${data}` : data}`;
