import Image from "next/image";
import label from "@/components/atoms/asside/label";
import { CSSProperties, ReactNode } from "react";
import TitlePage from "@/components/atoms/TitlePage";
import { useGetDashboardResume } from "@/queries/DashboardQuery";
import { List } from "@/queries/DataTarget";
import { FetchListType } from "@/types/DataTarget";
import { addThousandSeparator } from "@/utils/TextManip";

const Dashboard = () => {
  const { data: dashboardData, isLoading } = useGetDashboardResume();
  const { data: dataTarget } = List();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!dashboardData) {
    return <div>Error</div>;
  }

  // const dashboardData = data?.data!;
  const target: FetchListType = (dataTarget?.data as FetchListType) ?? {
    month: [],
    year: {
      akun_berlangganan_shipper: 0,
      akun_berlangganan_trucker: 0,
      app_download: 0,
      iklan_aktif_shipper: 0,
      iklan_aktif_trucker: 0,
      month: 0,
      perusahaan_terdaftar_shipper: 0,
      perusahaan_terdaftar_trucker: 0,
      web_traffic: 0,
      year: 0,
      shipper_user: 0,
      trucker_user: 0,
      user_registration: 0,
    },
  };

  return (
    <>
      <TitlePage title="Dashboard" />

      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-6">
          <DashboardCard
            altIcon="phone-icon-dashboard"
            icon="/icon/phone-icon-dashboard.svg"
            iconBackground="linear-gradient(0deg, rgba(218, 218, 218, 0.2) 0%, rgba(46, 79, 250, 0.2) 100%), rgba(16, 88, 196, 0.25)"
            label="Apps Download"
            labelDay="Yesterday"
            todayResult={dashboardData.app_download.today}
            lastDayResult={dashboardData.app_download.last_day}
            monthlyResult={dashboardData.app_download.monthly}
            lastmonthlyResult={dashboardData.app_download.vs_last_month}
            yearlyResult={dashboardData.app_download.yearly}
            lastyearlyResult={dashboardData.app_download.last_year}
            startToDate={dashboardData.app_download.start_to_date}
            monthlyTarget={
              target?.month?.find((e) => e.month == new Date().getMonth() + 1)
                ?.app_download ?? 0
            }
            yearTarget={target.year.app_download ?? 0}
            wrapperBackground="linear-gradient(279.81deg, rgba(44, 103, 254, 0.2) -0.1%, rgba(106, 85, 85, 0) 99.9%), #101C37"
          />
        </div>
        <div className="col-span-6">
          <DashboardCard
            altIcon="web-icon-dashboard"
            icon="/icon/web-icon-dashboard.svg"
            iconBackground="linear-gradient(0deg, rgba(151, 71, 255, 0.2) 0%, rgba(46, 213, 250, 0.2) 100%), rgba(16, 88, 196, 0.25)"
            wrapperBackground="linear-gradient(279.81deg, rgba(44, 103, 254, 0.2) -0.1%, rgba(106, 85, 85, 0) 99.9%), #101C37"
            label="Web Trafic"
            labelDay="Yesterday"
            todayResult={dashboardData.web_traffic.today}
            lastDayResult={dashboardData.web_traffic.last_day}
            monthlyResult={dashboardData.web_traffic.monthly}
            lastmonthlyResult={dashboardData.web_traffic.vs_last_month}
            yearlyResult={dashboardData.web_traffic.yearly}
            lastyearlyResult={dashboardData.web_traffic.last_year}
            startToDate={dashboardData.web_traffic.start_to_date}
            monthlyTarget={
              target?.month?.find((e) => e.month == new Date().getMonth() + 1)
                ?.web_traffic ?? 0
            }
            yearTarget={target.year.web_traffic ?? 0}
          />
        </div>

        <div className="col-span-12">
          <DashboardCardTotal
            label="Total Transaksi Langganan"
            total={dashboardData.total_transaction.total_transaction}
          />
        </div>

        <div className="col-span-12">
          <DashboardCardTotal
            label="Total Transaksi Golek koin"
            total={
              dashboardData.total_coin_used_transaction
                .total_coin_used_transaction
            }
            imageFormat={
              <Image
                className="mr-3"
                src={"/icon/golek_coin.svg"}
                alt="golek koin icon"
                width={30}
                height={30}
              />
            }
          />
        </div>

        <div className="col-span-12">
          <DashboardCardTotal
            label="Total Top up Golek koin"
            total={dashboardData.total_coin_transaction.total_coin_transaction}
          />
        </div>

        <div className="col-span-12">
          <DashboardCard
            altIcon="user-registrasi-icon"
            icon="/icon/user-registrasi-icon-dashboard.svg"
            iconBackground="linear-gradient(0deg, rgba(204, 204, 204, 0.2) 0%, rgba(250, 167, 46, 0.2) 100%), rgba(251, 165, 36, 0.2)"
            wrapperBackground="linear-gradient(279.81deg, rgba(44, 103, 254, 0.2) -0.1%, rgba(106, 85, 85, 0) 99.9%), #101C37"
            label="Total User Registrasi"
            todayResult={dashboardData.total_user_registration.today}
            lastDayResult={dashboardData.total_user_registration.last_day}
            monthlyResult={dashboardData.total_user_registration.monthly}
            lastmonthlyResult={
              dashboardData.total_user_registration.vs_last_month
            }
            yearlyResult={dashboardData.total_user_registration.yearly}
            lastyearlyResult={dashboardData.total_user_registration.last_year}
            startToDate={dashboardData.total_user_registration.start_to_date}
            monthlyTarget={
              target?.month?.find((e) => e.month == new Date().getMonth() + 1)
                ?.user_registration ?? 0
            }
            yearTarget={target.year.user_registration ?? 0}
          />
        </div>

        <div className="col-span-6">
          <DashboardCard
            altIcon="post-trucker-dashboard"
            icon="/icon/company-icon-dashboard.svg"
            iconBackground="linear-gradient(0deg, rgba(204, 204, 204, 0.2) 0%, rgba(30, 142, 246, 0.2) 100%), rgba(86, 174, 255, 0.2)"
            wrapperBackground="linear-gradient(279.81deg, rgba(44, 103, 254, 0.2) -0.1%, rgba(106, 85, 85, 0) 99.9%), #101C37"
            label="Total User Trucker"
            todayResult={dashboardData.trucker_user.today}
            lastDayResult={dashboardData.trucker_user.last_day}
            monthlyResult={dashboardData.trucker_user.monthly}
            lastmonthlyResult={dashboardData.trucker_user.vs_last_month}
            yearlyResult={dashboardData.trucker_user.yearly}
            lastyearlyResult={dashboardData.trucker_user.last_year}
            startToDate={dashboardData.trucker_user.start_to_date}
            monthlyTarget={
              target?.month?.find((e) => e.month == new Date().getMonth() + 1)
                ?.perusahaan_terdaftar_trucker ?? 0
            }
            yearTarget={target.year.perusahaan_terdaftar_trucker ?? 0}
          />
        </div>

        <div className="col-span-6">
          <DashboardCard
            altIcon="post-shipper-dashboard.svg"
            icon="/icon/company-icon-dashboard.svg"
            iconBackground="linear-gradient(0deg, rgba(204, 204, 204, 0.2) 0%, rgba(30, 142, 246, 0.2) 100%), rgba(86, 174, 255, 0.2)"
            wrapperBackground="linear-gradient(279.81deg, rgba(44, 103, 254, 0.2) -0.1%, rgba(106, 85, 85, 0) 99.9%), #101C37"
            label="Total User Shipper"
            todayResult={dashboardData.shipper_user.today}
            lastDayResult={dashboardData.shipper_user.last_day}
            monthlyResult={dashboardData.shipper_user.monthly}
            lastmonthlyResult={dashboardData.shipper_user.vs_last_month}
            yearlyResult={dashboardData.shipper_user.yearly}
            lastyearlyResult={dashboardData.shipper_user.last_year}
            startToDate={dashboardData.shipper_user.start_to_date}
            monthlyTarget={
              target?.month?.find((e) => e.month == new Date().getMonth() + 1)
                ?.perusahaan_terdaftar_shipper ?? 0
            }
            yearTarget={target.year.perusahaan_terdaftar_shipper ?? 0}
          />
        </div>

        <div className="col-span-12">
          <DashboardCard
            altIcon="user-registrasi-icon"
            icon="/icon/user-registrasi-icon-dashboard.svg"
            iconBackground="linear-gradient(0deg, rgba(204, 204, 204, 0.2) 0%, rgba(250, 167, 46, 0.2) 100%), rgba(251, 165, 36, 0.2)"
            wrapperBackground="linear-gradient(279.81deg, rgba(44, 103, 254, 0.2) -0.1%, rgba(106, 85, 85, 0) 99.9%), #101C37"
            label="Total User Trucker & Shipper"
            todayResult={dashboardData.user_registration.today}
            lastDayResult={dashboardData.user_registration.last_day}
            monthlyResult={dashboardData.user_registration.monthly}
            lastmonthlyResult={dashboardData.user_registration.vs_last_month}
            yearlyResult={dashboardData.user_registration.yearly}
            lastyearlyResult={dashboardData.user_registration.last_year}
            startToDate={dashboardData.user_registration.start_to_date}
            monthlyTarget={
              target?.month?.find((e) => e.month == new Date().getMonth() + 1)
                ?.trucker_shipper_user ?? 0
            }
            yearTarget={target.year.trucker_shipper_user ?? 0}
          />
        </div>

        <div className="col-span-6">
          <DashboardCard
            altIcon="post-trucker-dashboard"
            icon="/icon/post-trucker-dashboard.svg"
            iconBackground="linear-gradient(0deg, rgba(204, 204, 204, 0.2) 0%, rgba(30, 142, 246, 0.2) 100%), rgba(86, 174, 255, 0.2)"
            wrapperBackground="linear-gradient(279.81deg, rgba(44, 103, 254, 0.2) -0.1%, rgba(106, 85, 85, 0) 99.9%), #101C37"
            label="Total Iklan Trucker"
            todayResult={dashboardData.post_trucker.today}
            lastDayResult={dashboardData.post_trucker.last_day}
            monthlyResult={dashboardData.post_trucker.monthly}
            lastmonthlyResult={dashboardData.post_trucker.vs_last_month}
            yearlyResult={dashboardData.post_trucker.yearly}
            lastyearlyResult={dashboardData.post_trucker.last_year}
            startToDate={dashboardData.post_trucker.start_to_date}
            monthlyTarget={
              target?.month?.find((e) => e.month == new Date().getMonth() + 1)
                ?.iklan_aktif_trucker ?? 0
            }
            yearTarget={target.year.iklan_aktif_trucker ?? 0}
          />
        </div>

        <div className="col-span-6">
          <DashboardCard
            altIcon="post-shipper-dashboard.svg"
            icon="/icon/post-shipper-dashboard.svg"
            iconBackground="rgba(120, 84, 197, 0.25)"
            wrapperBackground="linear-gradient(279.81deg, rgba(44, 103, 254, 0.2) -0.1%, rgba(106, 85, 85, 0) 99.9%), #101C37"
            label="Total Iklan Shipper"
            todayResult={dashboardData.post_shipper.today}
            lastDayResult={dashboardData.post_shipper.last_day}
            monthlyResult={dashboardData.post_shipper.monthly}
            lastmonthlyResult={dashboardData.post_shipper.vs_last_month}
            yearlyResult={dashboardData.post_shipper.yearly}
            lastyearlyResult={dashboardData.post_shipper.last_year}
            startToDate={dashboardData.post_shipper.start_to_date}
            monthlyTarget={
              target?.month?.find((e) => e.month == new Date().getMonth() + 1)
                ?.iklan_aktif_shipper ?? 0
            }
            yearTarget={target.year.iklan_aktif_shipper ?? 0}
          />
        </div>

        <div className="col-span-6">
          <DashboardCard
            altIcon="post-trucker-dashboard"
            icon="/icon/post-trucker-dashboard.svg"
            iconBackground="linear-gradient(0deg, rgba(204, 204, 204, 0.2) 0%, rgba(30, 142, 246, 0.2) 100%), rgba(86, 174, 255, 0.2)"
            wrapperBackground="linear-gradient(279.81deg, rgba(44, 103, 254, 0.2) -0.1%, rgba(106, 85, 85, 0) 99.9%), #101C37"
            label="Total Keseluruhan Iklan Trucker"
            todayResult={dashboardData.post_all_trucker.today}
            lastDayResult={dashboardData.post_all_trucker.last_day}
            monthlyResult={dashboardData.post_all_trucker.monthly}
            lastmonthlyResult={dashboardData.post_all_trucker.vs_last_month}
            yearlyResult={dashboardData.post_all_trucker.yearly}
            lastyearlyResult={dashboardData.post_all_trucker.last_year}
            startToDate={dashboardData.post_all_trucker.start_to_date}
            monthlyTarget={
              target?.month?.find((e) => e.month == new Date().getMonth() + 1)
                ?.iklan_aktif_trucker ?? 0
            }
            yearTarget={target.year.iklan_aktif_trucker ?? 0}
          />
        </div>

        <div className="col-span-6">
          <DashboardCard
            altIcon="post-shipper-dashboard.svg"
            icon="/icon/post-shipper-dashboard.svg"
            iconBackground="rgba(120, 84, 197, 0.25)"
            wrapperBackground="linear-gradient(279.81deg, rgba(44, 103, 254, 0.2) -0.1%, rgba(106, 85, 85, 0) 99.9%), #101C37"
            label="Total Keseluruhan Iklan Shipper"
            todayResult={dashboardData.post_all_shipper.today}
            lastDayResult={dashboardData.post_all_shipper.last_day}
            monthlyResult={dashboardData.post_all_shipper.monthly}
            lastmonthlyResult={dashboardData.post_all_shipper.vs_last_month}
            yearlyResult={dashboardData.post_all_shipper.yearly}
            lastyearlyResult={dashboardData.post_all_shipper.last_year}
            startToDate={dashboardData.post_all_shipper.start_to_date}
            monthlyTarget={
              target?.month?.find((e) => e.month == new Date().getMonth() + 1)
                ?.iklan_aktif_shipper ?? 0
            }
            yearTarget={target.year.iklan_aktif_shipper ?? 0}
          />
        </div>

        <div className="col-span-12">
          <DashboardCard
            altIcon="berlangganan-dashboard.svg"
            icon="/icon/berlangganan-dashboard.svg"
            iconBackground="rgba(120, 84, 197, 0.25)"
            wrapperBackground="linear-gradient(279.81deg, rgba(44, 103, 254, 0.2) -0.1%, rgba(106, 85, 85, 0) 99.9%), #101C37"
            label="Total Trucker Langganan"
            todayResult={dashboardData.subscription_trucker.today}
            lastDayResult={dashboardData.subscription_trucker.last_day}
            monthlyResult={dashboardData.subscription_trucker.monthly}
            lastmonthlyResult={dashboardData.subscription_trucker.vs_last_month}
            yearlyResult={dashboardData.subscription_trucker.yearly}
            lastyearlyResult={dashboardData.subscription_trucker.last_year}
            startToDate={dashboardData.subscription_trucker.start_to_date}
            monthlyTarget={
              target?.month?.find((e) => e.month == new Date().getMonth() + 1)
                ?.akun_berlangganan_trucker ?? 0
            }
            yearTarget={target.year.akun_berlangganan_trucker ?? 0}
          />
        </div>

        <div className="h-10" />
      </div>
    </>
  );
};

export default Dashboard;

type TDashboardCard = {
  wrapperBackground: string;
  icon: string;
  altIcon: string;
  iconBackground: string;
  label: string;
  todayResult: number;
  lastDayResult: number;
  monthlyResult: number;
  lastmonthlyResult: number;
  yearlyResult: number;
  lastyearlyResult: number;
  startToDate: number;
  yearTarget?: number;
  monthlyTarget?: number;
  labelDay?: string;
};

const DashboardCard = ({
  altIcon,
  icon,
  iconBackground,
  label,
  wrapperBackground,
  todayResult,
  lastDayResult,
  monthlyResult,
  lastmonthlyResult,
  yearlyResult,
  lastyearlyResult,
  startToDate,
  yearTarget = 100,
  monthlyTarget = 100,
  labelDay = "Today",
}: TDashboardCard) => {
  const calculateVersus = (now: number, then: number): number => {
    return then == 0 && now == 0
      ? 0
      : then == 0
      ? now * 100
      : (now / then) * 100;
  };

  const vsLastDay = lastDayResult;
  const vsLastMonth = lastmonthlyResult;
  const vsLastYear = lastyearlyResult;

  const vsTargetMonthLess =
    monthlyTarget == 0
      ? 100
      : monthlyResult == 0
      ? 0
      : (monthlyResult / monthlyTarget) * 100;
  const vsTargetMonthThan =
    vsTargetMonthLess > 100 ? 0 : 100 - vsTargetMonthLess;
  const vsTargetMonthLessString = vsTargetMonthLess
    .toFixed(1)
    .replace(/\.00$/, "");
  const vsTargetMonthThanString = vsTargetMonthThan
    .toFixed(1)
    .replace(/\.00$/, "");

  const vsTargetYearLess =
    yearTarget == 0
      ? 100
      : yearlyResult == 0
      ? 0
      : (yearlyResult / yearTarget) * 100;
  const vsTargetYearThan = vsTargetYearLess > 100 ? 0 : 100 - vsTargetYearLess;
  const vsTargetYearLessString = vsTargetYearLess
    .toFixed(1)
    .replace(/\.00$/, "");
  const vsTargetYearThanString = vsTargetYearThan
    .toFixed(1)
    .replace(/\.00$/, "");

  return (
    <div
      className="flex min-h-47 w-full flex-col py-6 pl-9 pr-4"
      style={{
        borderRadius: "5px",
        background: wrapperBackground,
      }}
    >
      <div
        className="relative flex h-12 
       w-12 items-center justify-center rounded-full"
        style={{
          background: iconBackground,
        }}
      >
        <Image src={icon} alt={altIcon} width={18} height={28} />
      </div>

      <div className="mt-4 text-xl font-semibold text-white-300">{label}</div>

      <div className="mt-2 flex">
        <div className="mb-2 mr-10 text-lg font-semibold text-white-500">
          {labelDay} results
        </div>

        <div className="flex flex-col items-center justify-center text-center">
          <Image
            src={
              Number(vsLastDay) >= 100
                ? "/icon/arrow-drop-up.svg"
                : "/icon/arrow-drop-down.svg"
            }
            alt={"user-arrow-versus"}
            width={32}
            height={32}
          />

          <div className="mt-[2px] text-xs text-white-400">{vsLastDay}%</div>

          <div className="mt-[1px] text-xs text-white-400">vs last day</div>
        </div>
      </div>

      <div className="mb-2 flex items-center  pr-10">
        <div className="mr-2 text-3.5xl font-semibold text-white-300">
          {todayResult}
        </div>
      </div>

      <div className="flex flex-wrap ">
        <div className="flex w-1/2 flex-col justify-between pr-1 ">
          <div className="text-lg  font-semibold text-white-500 ">
            Monthly results
          </div>

          <div className="flex">
            <div className="flex w-3/5 flex-col justify-between">
              <div className="mr-2 text-2xl font-semibold text-white-300">
                {addThousandSeparator(monthlyResult)}
              </div>

              <div className="mt-[1px] text-xs text-white-400">vs target</div>
            </div>

            <div className="flex  w-2/5 flex-col items-center justify-center text-center">
              <Image
                src={
                  Number(vsLastMonth) >= 100
                    ? "/icon/arrow-drop-up.svg"
                    : "/icon/arrow-drop-down.svg"
                }
                alt={"user-arrow-versus"}
                width={32}
                height={32}
              />

              <div className="mt-[2px] text-xs text-white-400">
                {vsLastMonth}%
              </div>

              <div className="mt-[1px] text-xs text-white-400">
                vs last month
              </div>
            </div>
          </div>

          <div className="mt-2 flex h-5 w-full">
            {vsTargetMonthLess > 0 && (
              <div
                className="bg-secondary-pressed text-center text-sm text-white-300"
                style={{
                  width: `${vsTargetMonthLess < 10 ? 30 : vsTargetMonthLess}%`,
                }}
              >
                {vsTargetMonthLessString} %
              </div>
            )}

            {vsTargetMonthThan > 0 && (
              <div
                className="bg-danger-main text-center text-sm text-white-300"
                style={{ width: `${vsTargetMonthThan}%` }}
              >
                {vsTargetMonthThanString} %
              </div>
            )}
          </div>
        </div>

        <div className="flex w-1/2 flex-col justify-between pl-1">
          <div className="text-lg  font-semibold text-white-500 ">
            Yearly results
          </div>

          <div className="flex">
            <div className="flex w-3/5 flex-col justify-between">
              <div className="mr-2 text-2xl font-semibold text-white-300">
                {addThousandSeparator(yearlyResult)}
              </div>

              <div className="mt-[1px] text-xs text-white-400">vs target</div>
            </div>

            <div className="flex  w-2/5 flex-col items-center justify-center text-center">
              <Image
                src={
                  Number(vsLastYear) >= 100
                    ? "/icon/arrow-drop-up.svg"
                    : "/icon/arrow-drop-down.svg"
                }
                alt={"user-arrow-versus"}
                width={32}
                height={32}
              />

              <div className="mt-[2px] text-xs text-white-400">
                {vsLastYear}%
              </div>

              <div className="mt-[1px] text-xs text-white-400">
                vs last Year
              </div>
            </div>
          </div>

          <div className="mt-2 flex h-5 w-full">
            {vsTargetYearLess > 0 && (
              <div
                className="bg-secondary-pressed text-center text-sm text-white-300"
                style={{
                  width: `${vsTargetYearLess < 10 ? 30 : vsTargetYearLess}%`,
                }}
              >
                {vsTargetYearLessString} %
              </div>
            )}

            {vsTargetYearThan > 0 && (
              <div
                className="bg-danger-main text-center text-sm text-white-300"
                style={{ width: `${vsTargetYearThan}%` }}
              >
                {vsTargetYearThanString} %
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mt-4 w-full">
        <div className="text-lg  font-semibold text-white-500 ">
          Start To Date
        </div>

        <div className="mt-1 flex">
          <div className="flex w-3/5 flex-col">
            <div className="mr-2 text-2.5xl font-semibold text-white-300">
              {addThousandSeparator(startToDate)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type TDashboardCardTotal = {
  label: string;
  total: number;
  imageFormat?: ReactNode;
};

const DashboardCardTotal = ({
  label,
  total,
  imageFormat,
}: TDashboardCardTotal) => {
  return (
    <div
      className="flex min-h-47 w-full flex-col py-6 pl-9 pr-4"
      style={{
        borderRadius: "5px",
        background:
          "linear-gradient(279.81deg, rgba(44, 103, 254, 0.2) -0.1%, rgba(106, 85, 85, 0) 99.9%), #101C37",
      }}
    >
      <div
        className="relative flex h-12 
       w-12 items-center justify-center rounded-full"
        style={{
          background:
            "linear-gradient(0deg, rgba(218, 218, 218, 0.2) 0%, rgba(250, 46, 46, 0.2) 100%), rgba(16, 196, 168, 0.25)",
        }}
      >
        <Image
          src={"/icon/money-icon-dashboard.svg"}
          alt={"/money-icon-dashboard.svg"}
          width={18}
          height={28}
        />
      </div>

      <div className="mt-2 text-lg font-semibold text-white-300">{label}</div>

      <div className="mt-2 w-full">
        <div className="flex">
          <div className="flex w-3/5 flex-col">
            <div className="mr-2 flex text-3.5xl font-semibold text-white-300">
              {imageFormat ?? "Rp "} {addThousandSeparator(total)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
