import Button from "@/components/atoms/Button";
import Calendar from "@/components/molecules/Calendar";
import Inputfield from "@/components/atoms/InputField";
import Skeleton from "@/components/atoms/Skeleton";
import { SelectBox } from "@/components/molecules";
import { useState } from "react";
import SelectDropdown from "@/components/atoms/SelectDropdown";
import { Option } from "@/types/SelectBoxOption";
import Dashboard from "@/components/templates/dashboard/dashboard";

let opt: Option<string>[] = [{ label: 'Aasdfasdfasdfasdf', value: 'A' }, { label: 'Casdfasdfasdfasdf', value: 'C' }, { label: 'Basdfasdfasdfasdfasdf', value: 'B' }]

export default function Home() {
  // const [state, setState] = useState<string[]>([]);

  // console.log("is it tho", state);
  return (
    <>
      {/* <div className="flex h-screen items-center justify-center bg-neutral-300 text-white-0">
        <SelectDropdown options={opt} values={state} onChange={(state) => setState(state)} placeholder="BRUHHHHHHHHHHHHH" />
        <Button
          onClick={() => {
            setState([]);
          }}
        >
          Ye
        </Button>
        {JSON.stringify(state)}
      </div> */}

      <Dashboard />
    </>
  );
}
