import axios from '@/plugins/axios'
import { DashboardResume } from '@/types/Dashboard'
import { useQuery } from '@tanstack/react-query'

export const useGetDashboardResume = () => {
  return useQuery({
    queryKey: ['dashboard-resume'],
    queryFn: async (): Promise<DashboardResume> => {
      try {
        const result = await axios(`/admin/dashboard/resume`)
        return result.data
      } catch (error) {
        throw error
      }
    },
  })
}
