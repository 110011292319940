import { MouseEventHandler, ReactNode } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";

const TitlePage = ({
  title,
  children,
  includeBackButton = false,
  onBackButtonClicked,
}: {
  title: string | string[] | ReactNode | undefined;
  children?: ReactNode;
  includeBackButton?: boolean | ReactNode;
  onBackButtonClicked?: MouseEventHandler;
}) => {
  return (
    <div className="item-center mb-6 flex">
      {includeBackButton && (
        <i
          className="mr-4 cursor-pointer text-4xl"
          onClick={onBackButtonClicked}
        >
          {includeBackButton instanceof Object ? (
            includeBackButton
          ) : (
            <MdKeyboardArrowLeft />
          )}
        </i>
      )}
      <div
        className={`flex flex-1 items-center text-2.5xl font-bold capitalize`}
      >
        {title}
      </div>
      {children}
    </div>
  );
};

export default TitlePage;
